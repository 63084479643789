canvas {
    position: fixed;
    top: 0;
    left: 0;
}

:root {
    --bg-color: rgba(15, 15, 15, 0.95);
    --large-space: 500px;
    --space-350: 350px;
    --medium-space: 300px;
    --space-100: 100px;
    --small-space: 50px;

    font-family: brandon-grotesque, sans-serif;
    font-weight: 400;
    font-style: normal;

    scroll-behavior: smooth;
}

html, body {
    margin: 0;
    padding: 0;
    background-color: #161F3D;
}

main {
    color: white;
    z-index: 99;
    position: absolute;
    width: 100%;

    display: grid;
    grid-template-columns: repeat(12, 1fr);
}

#about {
    font-size: 2vw;
    margin-top: 300px;
    scroll-margin-top: 350px;
}

#projects {
    scroll-margin-top: 350px;
}

#contact {
    scroll-margin-top: 350px;
}

h1 {
    text-align: center;
    margin: 0;
}

h2 {
    margin: 0;
}

h1, h2, h3, blockquote {
    font-family: elevon, sans-serif;
    font-weight: 700;
    font-style: normal;
}


header {
    background: var(--bg-color);
    grid-column: 2 / span 5;
    font-size: 2.5rem;
    padding: 2rem;
    margin-bottom: var(--large-space);
}

section {
    grid-column: 2 / 8;
    padding: 1rem;
    background: var(--bg-color);
    font-size: 1.25rem;
    line-height: 1.5;
    margin-bottom: var(--medium-space);
}

blockquote {
    margin: 0;
    padding: 0;
    grid-column: 2 / span 9;
    margin-bottom: var(--large-space);
}

blockquote p {
    color: black;
    background-color: white;
    font-size: 4rem;
    display: inline;
    line-height: 1;
    padding: 1rem;
}

.right {
    grid-column: 6 / 12;
}

a {
    color: white;
    text-decoration: none;
    font-weight: bold;
    padding: 10px 0;
}

.icon {
    font-size: xx-large;
    padding: 0;
    margin: 0;
    font-weight: bold;
    text-align: center;
}

.socials {
    margin-bottom: var(--small-space);
    grid-column: 2 / 12;
}

i {
    margin-right: 20px;
}

navbar {
    z-index: 100;
    position: fixed;
    width: 100%;
    background-color: var(--bg-color);
    color: white;
    top: 0;
    left: 0;
    display: flex;
    align-items: center;
    justify-content: space-around;
}

navbar a {
    font-size: x-large;
    padding: 1rem;
}

.last-proj {
    margin-bottom: var(--large-space);
}

footer {
    margin-top: var(--space-100);
    grid-column: 1 / 14;
    z-index: 100;
    width: 100%;
    background-color: var(--bg-color);
    color: white;
    font-size: x-large;
    text-align: center;
}