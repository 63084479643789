canvas {
  position: fixed;
  top: 0;
  left: 0;
}

:root {
  --bg-color: #0f0f0ff2;
  --large-space: 500px;
  --space-350: 350px;
  --medium-space: 300px;
  --space-100: 100px;
  --small-space: 50px;
  scroll-behavior: smooth;
  font-family: brandon-grotesque, sans-serif;
  font-style: normal;
  font-weight: 400;
}

html, body {
  background-color: #161f3d;
  margin: 0;
  padding: 0;
}

main {
  color: #fff;
  z-index: 99;
  grid-template-columns: repeat(12, 1fr);
  width: 100%;
  display: grid;
  position: absolute;
}

#about {
  margin-top: 300px;
  scroll-margin-top: 350px;
  font-size: 2vw;
}

#projects, #contact {
  scroll-margin-top: 350px;
}

h1 {
  text-align: center;
  margin: 0;
}

h2 {
  margin: 0;
}

h1, h2, h3, blockquote {
  font-family: elevon, sans-serif;
  font-style: normal;
  font-weight: 700;
}

header {
  background: var(--bg-color);
  margin-bottom: var(--large-space);
  grid-column: 2 / span 5;
  padding: 2rem;
  font-size: 2.5rem;
}

section {
  background: var(--bg-color);
  margin-bottom: var(--medium-space);
  grid-column: 2 / 8;
  padding: 1rem;
  font-size: 1.25rem;
  line-height: 1.5;
}

blockquote {
  margin: 0;
  margin-bottom: var(--large-space);
  grid-column: 2 / span 9;
  padding: 0;
}

blockquote p {
  color: #000;
  background-color: #fff;
  padding: 1rem;
  font-size: 4rem;
  line-height: 1;
  display: inline;
}

.right {
  grid-column: 6 / 12;
}

a {
  color: #fff;
  padding: 10px 0;
  font-weight: bold;
  text-decoration: none;
}

.icon {
  text-align: center;
  margin: 0;
  padding: 0;
  font-size: xx-large;
  font-weight: bold;
}

.socials {
  margin-bottom: var(--small-space);
  grid-column: 2 / 12;
}

i {
  margin-right: 20px;
}

navbar {
  z-index: 100;
  background-color: var(--bg-color);
  color: #fff;
  justify-content: space-around;
  align-items: center;
  width: 100%;
  display: flex;
  position: fixed;
  top: 0;
  left: 0;
}

navbar a {
  padding: 1rem;
  font-size: x-large;
}

.last-proj {
  margin-bottom: var(--large-space);
}

footer {
  margin-top: var(--space-100);
  z-index: 100;
  background-color: var(--bg-color);
  color: #fff;
  text-align: center;
  grid-column: 1 / 14;
  width: 100%;
  font-size: x-large;
}
/*# sourceMappingURL=index.7a7bc26b.css.map */
